exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-data-processing-agreement-tsx": () => import("./../../../src/pages/data-processing-agreement.tsx" /* webpackChunkName: "component---src-pages-data-processing-agreement-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/GDPR.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nocode-automation-tsx": () => import("./../../../src/pages/nocode-automation.tsx" /* webpackChunkName: "component---src-pages-nocode-automation-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-visual-feedback-tsx": () => import("./../../../src/pages/visual-feedback.tsx" /* webpackChunkName: "component---src-pages-visual-feedback-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/BlogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-detail-blog-tsx": () => import("./../../../src/templates/DetailBlog.tsx" /* webpackChunkName: "component---src-templates-detail-blog-tsx" */)
}

